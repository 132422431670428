import React, { Component } from "react";
import logo from "../assets/logo.svg";
import { withRouter } from "react-router";
import "./Sidebar.css";
import SidebarItem from "./SidebarItem";
import { connect } from "react-redux";
import { clickMenuItem } from "../actions/index";
import contentStrings from "../constants/localization";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    var activeItem = 0;

    var menuItems = [
      {
        active: false,
        url: "",
        title: contentStrings.dashboard,
        icon: "fa-columns"
      }
    ];

    if (props.store.accountType == "farmer") {
      menuItems.push(
        {
          active: false,
          url: "farms",
          title: contentStrings.farms,
          icon: "fa-apple-alt"
        },
        {
          active: false,
          url: "coffeeBatches",
          title: contentStrings.coffeeBatches,
          icon: "fa-seedling"
        },
        {
          active: false,
          url: "permissions",
          title: contentStrings.permissionsTitle,
          icon: "fa-users"
        },
        {
          active: false,
          url: "tastings",
          title: contentStrings.cupProfiles,
          icon: "fa-coffee"
        }
      );

      if (props.location.pathname.includes("farms")) {
        activeItem = 1;
      }

      if (props.location.pathname.includes("coffeeBatches")) {
        activeItem = 2;
      }
      if (props.location.pathname.includes("permissions")) {
        activeItem = 3;
      }

      if (props.location.pathname.includes("tastings")) {
        activeItem = 4;
      }
    }

    if (props.store.accountType == "taster") {
      menuItems.push({
        active: false,
        url: "tastings",
        title: contentStrings.cupProfiles,
        icon: "fa-coffee"
      });

      if (props.location.pathname.includes("tastings")) {
        activeItem = 1;
      }
    }

    if (props.store.accountType == "cooperative") {
      menuItems.push(
        {
          active: false,
          url: "producers",
          title: contentStrings.producers,
          icon: "fa-user-alt"
        },
        {
          active: false,
          url: "farms",
          title: contentStrings.farms,
          icon: "fa-apple-alt"
        },
        {
          active: false,
          url: "coffeeBatches",
          title: contentStrings.coffeeBatches,
          icon: "fa-seedling"
        },
        {
          active: false,
          url: "permissions",
          title: contentStrings.permissionsTitle,
          icon: "fa-users"
        }
      );

      if (props.location.pathname.includes("producers")) {
        activeItem = 1;
      }

      if (props.location.pathname.includes("farms")) {
        activeItem = 2;
      }

      if (props.location.pathname.includes("coffeeBatches")) {
        activeItem = 3;
      }
      if (props.location.pathname.includes("permissions")) {
        activeItem = 4;
      }
    }

    if (props.store.accountType == "certifier") {
      menuItems.push({
        active: false,
        url: "certificates",
        title: contentStrings.certificates,
        icon: "fa-award"
      });

      if (props.location.pathname.includes("certificates")) {
        activeItem = 1;
      }
    }
    menuItems[activeItem].active = true;
    this.props.setActiveItem(activeItem);
    this.state = { activeItem: activeItem, menuItems: menuItems };
    this.setActiveLink = this.setActiveLink.bind(this);
  }

  setActiveLink(index) {
    this.props.setActiveItem(index);
    var menuItems = { ...this.state.menuItems };
    menuItems[this.state.activeItem].active = false;
    menuItems[index].active = true;
    this.setState({ activeItem: index });
    this.props.history.push(`/dashboard/${this.state.menuItems[index].url}`);
  }

  render() {
    const self = this;
    const activeMenuItem = this.props.store.activeMenuItem;
    return (
      <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0 ">
        <div className="main-navbar">
          <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
            <a className="navbar-brand w-100 mr-0" id="navbar-link" href="#">
              <div className="d-table m-auto">
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1"
                  src={logo}
                  alt="Affogato"
                />
              </div>
            </a>
            <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
              <i className="material-icons">&#xE5C4;</i>
            </a>
          </nav>
        </div>
        <form
          action="#"
          className="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none"
        >
          <div className="input-group input-group-seamless ml-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-search" />
              </div>
            </div>
            <input
              className="navbar-search form-control"
              type="text"
              placeholder="Search for something..."
              aria-label="Search"
            />
          </div>
        </form>
        <div className="nav-wrapper">
          <ul className="nav flex-column">
            {this.state.menuItems.map(function(menuItem, index) {
              var active = "";
              if (activeMenuItem == index) {
                active = "active";
              }
              return (
                <SidebarItem
                  key={index}
                  id={index}
                  active={active}
                  url={menuItem.url}
                  iconClass={menuItem.icon}
                  title={menuItem.title}
                  handleClick={self.setActiveLink}
                />
              );
            })}
          </ul>
        </div>
      </aside>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: activeMenuItem => dispatch(clickMenuItem(activeMenuItem))
  };
};

const mapStateToProps = state => {
  return { store: state };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
