import React, { Component } from "react";
import DataTable from "./DataTable";
import DataTableButton from "./DataTableButton";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import Blockies from "react-blockies";
//TODO: Reload datatable on blockchain confirm
//TODO: show tostify

class Producers extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.state = {
      producers: null,
      status: "initialized"
    };
  }

  componentDidMount() {
    axios
      .get(
        `${constants.REST_URL}/actors/${
          this.props.drizzleState.accounts[0]
        }/allowers`
      )
      .then(
        response => {
          this.setState({ producers: response.data, status: "complete" });
        },
        error => {
          //TODO: do something with the error
          this.setState({ status: "complete" });
        }
      );
  }

  render() {
    if (this.state.producers == null || this.state.producers.length == 0) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.producers}
              titleSingular={contentStrings.producer}
              url="producers"
            />
          );
        default:
          return <Loading />;
      }
    }

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    const columns = [
      contentStrings.profileImage,
      contentStrings.nameColumn,
      contentStrings.countryColumn,
      contentStrings.department,
      contentStrings.farms,
      contentStrings.coffeeBatches,
      contentStrings.permissionsTitle
    ];
    const rows = [];
    _.forEach(this.state.producers, function(producer) {
      var currentProducer = [];
      var image = (
        <Blockies
          seed={producer.address}
          size={8}
          scale={10}
          className="identicon user-avatar rounded-circle mr-2"
        />
      );
      if (producer.image_hash != "") {
        image = (
          <img
            src={`${constants.IPFS_URL}/${producer.image_hash}`}
            width="80"
            className="user-avatar rounded-circle mr-2"
          />
        );
      }
      currentProducer.push(image);
      currentProducer.push(producer.name);
      currentProducer.push(producer.country);
      currentProducer.push(producer.region);
      currentProducer.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link
            to={`/dashboard/producers/${producer.id}/farms/new`}
            className="ml-2"
          >
            <button type="button" className="btn btn-accent form-control  ">
              <i className="fas fa-plus-circle mr-1" /> {contentStrings.add}
            </button>
          </Link>
        </div>
      );
      currentProducer.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link
            to={`/dashboard/producers/${producer.id}/coffeeBatches/new`}
            className="ml-2"
          >
            <button type="button" className="btn btn-accent form-control  ">
              <i className="fas fa-plus-circle mr-1" /> {contentStrings.add}
            </button>
          </Link>
        </div>
      );
      currentProducer.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link
            to={`/dashboard/producers/${producer.address}/permissions`}
            className="ml-2"
          >
            <button type="button" className="btn btn-accent form-control  ">
              <i className="fas fa-plus-circle mr-1" /> {contentStrings.add}
            </button>
          </Link>
        </div>
      );

      rows.push(currentProducer);
    });

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.producers}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              <Link to="/dashboard/producers/new" className="">
                <button type="button" className="btn btn-accent form-control">
                  <i className="fas fa-user mr-1" />
                  {contentStrings.newProducer}
                </button>
              </Link>
            </div>
          </div>
          <div className="row ">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeproducer}</h6>
              </div>
              <DataTable id="currentBatches" columns={columns} rows={rows} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Producers);
