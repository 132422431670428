import React, { Component } from "react";
import { withRouter } from "react-router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Loading from "./Loading";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import {
  FormGroup,
  InputGroupAddon,
  InputGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";
import ipfs from "../scripts/ipfs";
import { toast } from "react-toastify";
import { css } from "glamor";

//TODO: show toastify when created or updated
//TODO: Refactor name to Farm Form.
//TODO: Change url to constant
//TODO: url change depending on enviroment

class NewProducer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      region: "",
      name: "",
      email: "",
      accountAddress: "",
      privateKey: "",
      accountType: "farmer",
      profileImageText: contentStrings.selectProfileImage,
      imageHash: "",
      bio: "",
      buffer: "",
      imageLoading: false,
      redirect: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: "",
      locale: contentStrings.locale
    };

    this.contracts = props.drizzle.contracts;
    this.drizzle = props.drizzle;
    this.web3 = props.drizzle.web3;

    this.onChangeAccountAddress = this.onChangeAccountAddress.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onSelectAccountType = this.onSelectAccountType.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeBio = this.onChangeBio.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.generateAddress = this.generateAddress.bind(this);
    this.registerActor = this.registerActor.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onChangeAccountAddress(event) {
    this.setState({ accountAddress: event.target.value, privateKey: "" });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeBio(event) {
    this.setState({ bio: event.target.value });
  }

  onSelectAccountType(event) {
    this.setState({ accountType: event.target.value });
  }

  onSelectCountry(value) {
    this.setState({ country: value });
  }

  onSelectRegion(value) {
    this.setState({ region: value });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  generateAddress() {
    let account = this.props.drizzle.web3.eth.accounts.create();
    this.setState({
      accountAddress: account.address,
      privateKey: account.privateKey
    });
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });
            const url = `${constants.REST_URL}/actors`;
            const data = {
              address: this.state.accountAddress,
              name: this.state.name,
              typeOfActor: this.state.accountType,
              country: this.state.country,
              region: this.state.region,
              email: this.state.email,
              image_hash: this.state.imageHash,
              bio: this.state.bio,
              pkey: this.state.privateKey,
              locale: this.state.locale
            };
            const options = {
              method: "POST",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              data: qs.stringify(data),
              url
            };
            axios(options)
              .then(response => {
                const url = `${constants.REST_URL}/actors/${
                  this.state.accountAddress
                }/alloweds`;
                const data = {
                  allowed_address: this.props.drizzleState.accounts[0],
                  isAllowed: true
                };
                const options = {
                  method: "POST",
                  headers: {
                    "content-type": "application/x-www-form-urlencoded"
                  },
                  data: qs.stringify(data),
                  url
                };
                axios(options)
                  .then(response => {
                    this.props.history.push("/dashboard/producers");
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        }
      }
    });
  }

  onFarmNameChange(event) {
    this.setState({ farmName: event.target.value });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  onStoryChange(event) {
    this.setState({ story: event.target.value });
  }

  onVillageChange(event) {
    this.setState({ village: event.target.value });
  }

  //Take file input from user
  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ imageLoading: true });
    var profileImageText = contentStrings.selectProfileImage;
    if (event.target.files[0] != null) {
      profileImageText = event.target.files[0].name;
    }

    // this.setState({ profileImageText: "Loading..." });
    const file = event.target.files[0];
    if (file) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader, profileImageText);
    } else {
      this.setState({
        profileImageText: contentStrings.selectProfileImage,
        buffer: "",
        imageLoading: false
      });
    }
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, profileImageText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ profileImageText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  onFormSubmit = async event => {
    event.preventDefault();
    if (this.state.imageLoading) {
      let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
        position: "bottom-right",
        autoClose: false
      });
      await ipfs.add(this.state.buffer, (err, ipfsHash) => {
        toast.update(toastId, {
          render: `☕ ${contentStrings.uploadComplete}`,
          autoClose: true,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        this.setState({ imageHash: ipfsHash[0].hash });
        this.registerActor();
      });
    } else {
      this.registerActor();
    }
  };

  registerActor() {
    if (this.state.country != "" && this.state.region != "") {
      const stackId = this.contracts.ActorFactory.methods.cooperativeAddActor.cacheSend(
        this.web3.utils.utf8ToHex(this.state.name),
        this.web3.utils.utf8ToHex(this.state.accountType),
        this.web3.utils.utf8ToHex(this.state.country),
        this.web3.utils.utf8ToHex(this.state.region),
        this.web3.utils.utf8ToHex(this.state.email),
        this.state.imageHash,
        this.state.bio,
        this.state.accountAddress,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    }
  }

  render() {
    const { country, region } = this.state;

    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.producers}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountAddress}</label>
                    <InputGroup>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`${contentStrings.producer} ${
                          contentStrings.accountAddress
                        }`}
                        onChange={this.onChangeAccountAddress}
                        value={this.state.accountAddress}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="btn btn-accent"
                          onClick={this.generateAddress}
                        >
                          {contentStrings.generateAddress}
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.emailAddress}s</label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder={contentStrings.emailMessage}
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.nameColumn}</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.nameColumn}
                      onChange={this.onChangeName}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.profileImage}</Label>
                    <Input type="file" onChange={this.captureFile} />
                    <FormText color="muted">
                      {this.state.profileImageText}
                    </FormText>
                  </div>
                  <FormGroup>
                    <Label for="">{contentStrings.shortBio}</Label>
                    <Input
                      type="textarea"
                      placeholder={contentStrings.shortBioPlaceholder}
                      onChange={this.onChangeBio}
                    />
                  </FormGroup>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountType}</label>
                    <select
                      className="form-control"
                      value={this.state.accountType}
                      onChange={this.onSelectAccountType}
                    >
                      <option value="farmer">{contentStrings.farmer}</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.countryColumn}</label>
                    <CountryDropdown
                      value={country}
                      classes="form-control"
                      onChange={this.onSelectCountry}
                      defaultOptionLabel={contentStrings.selectCountry}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.regionColumn}</label>
                    <RegionDropdown
                      classes="form-control"
                      country={country}
                      value={region}
                      onChange={this.onSelectRegion}
                      defaultOptionLabel={contentStrings.selectRegion}
                    />
                  </div>{" "}
                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-user-alt fa-sidebar" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

export default withRouter(NewProducer);
