import React, { Component } from "react";
import { withRouter } from "react-router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Loading from "./Loading";
import axios from "axios";
import { connect } from "react-redux";
import { clickMenuItem } from "../actions/index";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";

//TODO: show toastify when created or updated
//TODO: Refactor name to Farm Form.
//TODO: Change url to constant
//TODO: url change depending on enviroment

class NewFarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      producerId: 0,
      producerName: "",
      country: "",
      region: "",
      farmName: "",
      story: "",
      village: "",
      status: "initialized",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.contracts = props.drizzle.contracts;
    this.drizzle = props.drizzle;
    this.web3 = props.drizzle.web3;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFarmNameChange = this.onFarmNameChange.bind(this);
    this.onStoryChange = this.onStoryChange.bind(this);
    this.onVillageChange = this.onVillageChange.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            var id = 0;
            var url = "";
            var method = "POST";

            if (this.props.isUpdate != null) {
              id = this.props.match.params.id;
              method = "PUT";
              url = `${constants.REST_URL}/farms/${id}`;
            } else {
              id =
                drizzleState.transactions[transactionHash].receipt.events
                  .LogCooperativeAddFarm.returnValues._id;
              url = `${constants.REST_URL}/actors/${
                this.state.producerAddress
              }/farms`;
            }

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });

            const data = {
              id: id,
              name: this.state.farmName,
              country: this.state.country,
              region: this.state.region,
              village: this.state.village,
              story: this.state.story
            };
            const options = {
              method: method,
              headers: { "content-type": "application/x-www-form-urlencoded" },
              data: qs.stringify(data),
              url
            };
            axios(options)
              .then(response => {
                this.props.setActiveItem(2);
                this.props.history.push("/dashboard/farms");
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        }
      }
    });

    var producerId = 0;

    if (this.props.isUpdate != null) {
      axios
        .get(`${constants.REST_URL}/farms/${this.props.match.params.id}`)
        .then(
          response => {
            const farm = response.data;
            var producerId = farm.actor_id;
            this.setState({
              producerId: farm.actor_id,
              country: farm.country,
              region: farm.region,
              farmName: farm.name,
              story: farm.story,
              village: farm.village,
              status: "complete"
            });

            axios.get(`${constants.REST_URL}/actors/id/${producerId}`).then(
              response => {
                const producer = response.data;
                this.setState({
                  producerName: producer.name,
                  producerAddress: producer.address,
                  status: "complete"
                });
              },
              error => {
                //TODO: do something with the error
                this.setState({ status: "complete" });
              }
            );
          },
          error => {
            //TODO: do something with the error
            this.setState({ status: "complete" });
          }
        );
    } else {
      axios
        .get(`${constants.REST_URL}/actors/id/${this.props.match.params.id}`)
        .then(
          response => {
            const producer = response.data;
            this.setState({
              producerName: producer.name,
              producerAddress: producer.address,
              status: "complete"
            });
          },
          error => {
            //TODO: do something with the error
            this.setState({ status: "complete" });
          }
        );
    }
  }

  onFarmNameChange(event) {
    this.setState({ farmName: event.target.value });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  onStoryChange(event) {
    this.setState({ story: event.target.value });
  }

  onVillageChange(event) {
    this.setState({ village: event.target.value });
  }

  onFormSubmit(event) {
    event.preventDefault();

    if (this.props.isUpdate != null) {
      const stackId = this.contracts.FarmFactory.methods.cooperativeUpdateFarm.cacheSend(
        this.props.match.params.id,
        this.web3.utils.utf8ToHex(this.state.farmName),
        this.web3.utils.utf8ToHex(this.state.country),
        this.web3.utils.utf8ToHex(this.state.region),
        this.web3.utils.utf8ToHex(this.state.village),
        this.state.story,
        this.state.producerAddress,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    } else {
      const stackId = this.contracts.FarmFactory.methods.cooperativeAddFarm.cacheSend(
        this.web3.utils.utf8ToHex(this.state.farmName),
        this.web3.utils.utf8ToHex(this.state.country),
        this.web3.utils.utf8ToHex(this.state.region),
        this.web3.utils.utf8ToHex(this.state.village),
        this.state.story,
        this.state.producerAddress,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    }
  }

  render() {
    if (this.state.farms == null && this.props.isUpdate != null) {
      switch (this.state.status) {
        case "complete":
          break;
        default:
          return <Loading />;
          break;
      }
    }
    const { country, region } = this.state;

    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.farms}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <Label for="">
                      {contentStrings.producer} {contentStrings.name}
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={this.state.producerName}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.farmName}</Label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.farmName}
                      value={this.state.farmName}
                      onChange={this.onFarmNameChange}
                    />
                  </div>
                  <Label for="">{contentStrings.countryColumn}</Label>
                  <div className="form-group">
                    <CountryDropdown
                      value={country}
                      classes="form-control"
                      onChange={val => this.selectCountry(val)}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.regionColumn}</Label>
                    <RegionDropdown
                      classes="form-control"
                      country={country}
                      value={region}
                      onChange={val => this.selectRegion(val)}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.village}</Label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.village}
                      onChange={this.onVillageChange}
                      value={this.state.village}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.farmStory}</Label>
                    <textarea
                      className="form-control "
                      placeholder={contentStrings.farmStoryPlaceholder}
                      onChange={this.onStoryChange}
                      value={this.state.story}
                    />
                  </div>

                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-apple-alt fa-sidebar" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: activeMenuItem => dispatch(clickMenuItem(activeMenuItem))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NewFarm)
);
