import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import axios from "axios";
import _ from "lodash";
import QRCode from "qrcode.react";
import ipfs from "../scripts/ipfs";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import qs from "qs";
import { toast } from "react-toastify";
import { css, readOnly } from "glamor";
import CheckAccountPermissions from "./CheckAccountPermissions";

class NewTasting extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.state = {
      coffeeBatch: null,
      owner: null,
      status: "initialized",
      aroma: "",
      sweetness: "",
      flavor: "",
      acidity: "",
      body: "",
      aftertaste: "",
      imageHash: "",
      cuppingNote: "",
      buffer: "",
      fileText: contentStrings.selectCupProfileImage,
      imageLoading: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeAroma = this.onChangeAroma.bind(this);
    this.onChangeSweetness = this.onChangeSweetness.bind(this);
    this.onChangeFlavor = this.onChangeFlavor.bind(this);
    this.onChangeAcidity = this.onChangeAcidity.bind(this);
    this.onChangeBody = this.onChangeBody.bind(this);
    this.onChangeAftertaste = this.onChangeAftertaste.bind(this);
    this.onChangeCuppingNote = this.onChangeCuppingNote.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  componentWillUnmount() {
    if (this.props.isUpdate == null) {
      this.unsubscribe();
    }
  }

  onChangeAroma(event) {
    this.setState({ aroma: event.target.value });
  }
  onChangeSweetness(event) {
    this.setState({ sweetness: event.target.value });
  }
  onChangeFlavor(event) {
    this.setState({ flavor: event.target.value });
  }
  onChangeAcidity(event) {
    this.setState({ acidity: event.target.value });
  }
  onChangeBody(event) {
    this.setState({ body: event.target.value });
  }
  onChangeAftertaste(event) {
    this.setState({ aftertaste: event.target.value });
  }
  onChangeImage(event) {
    this.setState({ imageHash: event.target.value });
  }
  onChangeCuppingNote(event) {
    this.setState({ cuppingNote: event.target.value });
  }

  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ imageLoading: true });
    var fileText = contentStrings.selectCupProfileImage;
    if (event.target.files[0] != null) {
      fileText = event.target.files[0].name;
    }

    const file = event.target.files[0];
    if (file) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader, fileText);
    } else {
      this.setState({
        fileText: contentStrings.selectCupProfileImage,
        buffer: "",
        imageLoading: false
      });
    }
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, fileText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ fileText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  async onFormSubmit(event) {
    event.preventDefault();

    if (this.state.imageLoading) {
      let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
        position: "bottom-right",
        autoClose: false
      });
      await ipfs.add(this.state.buffer, (err, ipfsHash) => {
        toast.update(toastId, {
          render: `☕ ${contentStrings.uploadComplete}`,
          autoClose: true,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        this.setState({ imageHash: ipfsHash[0].hash });
        this.saveTasting();
      });
    } else {
      this.saveTasting();
    }
  }

  saveTasting() {
    const cuppingNote = this.state.cuppingNote * 100;
    const stackId = this.contracts.TastingFactory.methods.addCupProfile.cacheSend(
      this.state.owner.address,
      this.state.coffeeBatch.id,
      this.web3.utils.utf8ToHex(this.state.aroma),
      this.web3.utils.utf8ToHex(this.state.sweetness),
      this.web3.utils.utf8ToHex(this.state.flavor),
      this.web3.utils.utf8ToHex(this.state.acidity),
      this.web3.utils.utf8ToHex(this.state.body),
      this.web3.utils.utf8ToHex(this.state.aftertaste),
      this.state.imageHash,
      cuppingNote,
      { from: this.props.drizzleState.accounts[0] }
    );
    this.setState({ transactionId: stackId });
  }

  async componentDidMount() {
    if (this.props.isUpdate != null) {
      axios
        .get(`${constants.REST_URL}/tastings/${this.props.match.params.id}`)
        .then(
          response => {
            const tasting = response.data;
            console.log(tasting);
            this.setState({
              aroma: tasting.aroma,
              sweetness: tasting.sweetness,
              flavor: tasting.flavor,
              acidity: tasting.acidity,
              body: tasting.body,
              aftertaste: tasting.aftertaste,
              cuppingNote: tasting.cuppingNote,
              imageHash: tasting.image_hash,
              status: "complete"
            });
          },
          error => {
            //TODO: do something with the error
            this.setState({ status: "complete" });
          }
        );
    } else {
      try {
        const response = await axios.get(
          `${constants.REST_URL}/coffeeBatches/${
            this.props.match.params.coffeeBatchId
          }`
        );

        var coffeeBatch = null;
        if (response) {
          coffeeBatch = response.data;
        }
        const responseActor = await axios.get(
          `${constants.REST_URL}/actors/id/${coffeeBatch.owner_id}`
        );
        var actor = null;
        if (responseActor) {
          actor = responseActor.data;
        }

        this.setState({
          coffeeBatch: coffeeBatch,
          owner: actor,
          status: "complete"
        });
      } catch (error) {
        this.setState({ status: "complete" });
      }
    }
    const { drizzle } = this.props;

    if (this.props.isUpdate == null) {
      // subscribe to changes in the store
      this.unsubscribe = drizzle.store.subscribe(() => {
        // every time the store updates, grab the state from drizzle
        const drizzleState = drizzle.store.getState();

        // check to see if it's ready, if so, update local component state
        if (drizzleState.drizzleStatus.initialized) {
          if (drizzleState.transactionStack[this.state.transactionId]) {
            const transactionHash =
              drizzleState.transactionStack[this.state.transactionId];
            if (
              drizzleState.transactions[transactionHash].status == "pending" &&
              this.state.modalPending
            ) {
              this.setState({
                transactionHash: transactionHash,
                modal: true,
                modalTitle: contentStrings.modalSubmitedTitle,
                modalBody: contentStrings.modalSubmitedText,
                modalPending: false
              });
            }
            if (
              drizzleState.transactions[transactionHash].status == "success" &&
              this.state.modalSuccess
            ) {
              const id =
                drizzleState.transactions[transactionHash].receipt.events
                  .LogAddCupProfile.returnValues._id;

              this.setState({
                transactionHash: transactionHash,
                modal: true,
                modalTitle: contentStrings.modalSuccessTitle,
                modalBody: `${contentStrings.modalSuccessText} ${
                  this.state.transactionHash
                }`,
                modalSuccess: false
              });
              const url = `${constants.REST_URL}/coffeeBatches/${
                this.state.coffeeBatch.id
              }/tastings`;
              const data = {
                id: id,
                coffee_batch_id: this.state.coffeeBatch.id,
                actor_address: this.props.drizzleState.accounts[0],
                aroma: this.state.aroma,
                sweetness: this.state.sweetness,
                flavor: this.state.flavor,
                acidity: this.state.acidity,
                body: this.state.body,
                aftertaste: this.state.aftertaste,
                image_hash: this.state.imageHash,
                cuppingNote: this.state.cuppingNote
              };
              const options = {
                method: "POST",
                headers: {
                  "content-type": "application/x-www-form-urlencoded"
                },
                data: qs.stringify(data),
                url
              };
              axios(options)
                .then(response => {
                  this.props.history.push(`/dashboard/tastings`);
                })
                .catch(function(error) {
                  console.log(error);
                });
            }
          }
        }
      });
    }
  }

  render() {
    if (this.state.status == "waiting" || this.state.status == "initialized") {
      return <Loading />;
    }

    var saveButton = "";
    var subTitle = contentStrings.newCupProfile;

    if (this.state.coffeeBatch == null) {
      subTitle = "";
    } else {
      saveButton = (
        <button className="btn btn-accent ml-auto ">
          <i className="fas fa-coffee fa-sidebar" />
          <span className="ml-1"> {this.props.title}</span>
        </button>
      );

      //TODO: refactor this
      const columns = [
        contentStrings.qrCode,
        contentStrings.farm,
        contentStrings.variety,
        contentStrings.altitude,
        contentStrings.process,
        contentStrings.batchSize
      ];
      var rows = [];
      const coffeeBatch = this.state.coffeeBatch;
      var currentCoffeeBatch = [];
      currentCoffeeBatch.push(
        <div className="mt-2">
          <QRCode
            size={80}
            value={`${constants.REST_URL}/coffeeBatches/${coffeeBatch.id}`}
          />
        </div>
      );
      currentCoffeeBatch.push(coffeeBatch.farm_name);
      currentCoffeeBatch.push(coffeeBatch.variety);
      currentCoffeeBatch.push(coffeeBatch.altitude);
      currentCoffeeBatch.push(coffeeBatch.process);
      currentCoffeeBatch.push(coffeeBatch.size / 100);
      rows.push(currentCoffeeBatch);
    }
    let readOnly = false;
    if (this.props.isUpdate) {
      readOnly = true;
    }

    //TODO: remove header and footer from data table
    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.cupProfiles}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div>
          <div className="col-lg-12 col-md-12">
            <div className="mb-3">
              <h6 className="mt-0">{subTitle}</h6>
            </div>
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.aroma}</label>
                    <input
                      type="text"
                      className="form-control mb-3 "
                      placeholder={contentStrings.aroma}
                      onChange={this.onChangeAroma}
                      value={this.state.aroma}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.sweetness}</label>
                    <input
                      type="text"
                      className="form-control mb-3 "
                      placeholder={contentStrings.sweetness}
                      onChange={this.onChangeSweetness}
                      value={this.state.sweetness}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.flavor}</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={contentStrings.flavor}
                      onChange={this.onChangeFlavor}
                      value={this.state.flavor}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.acidity}</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={contentStrings.acidity}
                      onChange={this.onChangeAcidity}
                      value={this.state.acidity}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.body}</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={contentStrings.body}
                      onChange={this.onChangeBody}
                      value={this.state.body}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.aftertaste}</label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={contentStrings.aftertaste}
                      onChange={this.onChangeAftertaste}
                      value={this.state.aftertaste}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.cuppingNote}</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder={contentStrings.cuppingNote}
                      onChange={this.onChangeCuppingNote}
                      value={this.state.cuppingNote}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.imageColumn}</label>
                    {this.props.isUpdate == null && (
                      <div className="form-group custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={this.captureFile}
                          id="customFile"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {this.state.fileText}
                        </label>
                      </div>
                    )}
                    {this.props.isUpdate != null && this.state.imageHash != "" && (
                      <div className="form-group">
                        <img
                          src={`${constants.IPFS_URL}${this.state.imageHash}`}
                        />
                      </div>
                    )}
                  </div>
                  {saveButton}
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

export default withRouter(NewTasting);
