import React, { Component } from "react";
import DataTable from "./DataTable";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import QRCode from "qrcode.react";
import Modal from "react-awesome-modal";
import copy from "copy-to-clipboard";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { connect } from "react-redux";
import CheckAccountPermissions from "./CheckAccountPermissions";
import coffeeBatchProcesses from "../constants/choices";

class Tastings extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.state = {
      tastings: null,
      status: "initialized"
    };
  }

  componentDidMount() {
    axios
      .get(
        `${constants.REST_URL}/actors/${
          this.props.drizzleState.accounts[0]
        }/allowers/coffeeBatches`
      )
      .then(
        response => {
          this.setState({ tastings: response.data, status: "complete" });
        },
        error => {
          //TODO: do something with the error
          this.setState({ status: "complete" });
        }
      );
  }

  render() {
    if (this.state.tastings == null || this.state.tastings.length == 0) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.cupProfiles}
              titleSingular={contentStrings.cupProfile}
              gender="f"
              url="farms"
              removeButton={true}
              message={contentStrings.emptyTasting}
            />
          );
          break;

        default:
          return <Loading />;
          break;
      }
    }

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    const columns = [
      contentStrings.farm,
      contentStrings.variety,
      contentStrings.altitude,
      contentStrings.process,
      contentStrings.batchSize,
      contentStrings.actionsColumn
    ];
    const rows = [];
    _.forEach(this.state.tastings, function(tasting) {
      var url = `/dashboard/coffeeBatches/${tasting.id}/tastings/new`;
      var btnIcon = "fas fa-plus";
      var currentFarm = [];

      if (tasting.tasting_id !== null) {
        url = `/dashboard/tastings/edit/${tasting.tasting_id}`;
        btnIcon = "fas fa-eye";
      }

      currentFarm.push(tasting.farm_name);
      currentFarm.push(tasting.variety);
      currentFarm.push(tasting.altitude);
      currentFarm.push(coffeeBatchProcesses[tasting.process]);
      currentFarm.push(tasting.size / 100);

      currentFarm.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link to={url} className="">
            <button type="button" className="btn btn-accent form-control ">
              <i className={btnIcon} />
            </button>
          </Link>
        </div>
      );
      rows.push(currentFarm);
    });

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.cupProfiles}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto" />
          </div>
          <div className="row ">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeCupProfiles}</h6>
              </div>
              <DataTable id="currentBatches" columns={columns} rows={rows} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Tastings);
