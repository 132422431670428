import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { connect } from "react-redux";
import { clickMenuItem } from "../actions/index";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import coffeeBatchProcesses from "../constants/choices";
import CheckAccountPermissions from "./CheckAccountPermissions";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";

//TODO: remove decimals
class NewCoffeeBatch extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.state = {
      producerName: "",
      farms: null,
      status: "initialized",
      selectedFarm: "0",
      altitude: "",
      variety: "",
      process: "0",
      batchSize: "",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.newFarmClick = this.newFarmClick.bind(this);
    this.onChangeSelectedFarm = this.onChangeSelectedFarm.bind(this);
    this.onChangeAltitude = this.onChangeAltitude.bind(this);
    this.onChangeVariety = this.onChangeVariety.bind(this);
    this.onChangeProcess = this.onChangeProcess.bind(this);
    this.onChangeBatchSize = this.onChangeBatchSize.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    if (this.props.isUpdate != null) {
      axios
        .get(
          `${constants.REST_URL}/coffeeBatches/${this.props.match.params.id}`
        )
        .then(
          response => {
            const coffeeBatch = response.data;
            const batchSize = coffeeBatch.size / 100;

            this.setState({
              selectedFarm: coffeeBatch.farm_id,
              altitude: coffeeBatch.altitude,
              variety: coffeeBatch.variety,
              process: coffeeBatch.process,
              batchSize: batchSize,
              status: "complete"
            });

            this.loadProducerFarms(coffeeBatch.owner_id);
          },
          error => {
            //TODO: do something with the error
            this.setState({ status: "complete" });
          }
        );
    } else {
      this.loadProducerFarms(this.props.match.params.id);
    }

    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            var id = 0;
            if (this.props.isUpdate != null) {
              id = this.props.match.params.id;
            } else {
              id =
                drizzleState.transactions[transactionHash].receipt.events
                  .LogCooperativeAddCoffeeBatch.returnValues._id;
            }

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });

            var httpMethod = "POST";
            var url = `${constants.REST_URL}/farms/${
              this.state.selectedFarm
            }/coffeeBatches`;

            if (this.props.isUpdate != null) {
              url = `${constants.REST_URL}/coffeeBatches/${id}`;
              httpMethod = "PUT";
            }

            const batchSize = this.state.batchSize * 100;
            const data = {
              id: id,
              farm_id: this.state.selectedFarm,
              altitude: this.state.altitude,
              variety: this.state.variety,
              process: this.state.process,
              size: batchSize
            };

            const options = {
              method: httpMethod,
              headers: { "content-type": "application/x-www-form-urlencoded" },
              data: qs.stringify(data),
              url
            };

            axios(options)
              .then(response => {
                this.props.history.push("/dashboard/coffeeBatches");
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        }
      }
    });
  }

  loadProducerFarms(producerId) {
    axios.get(`${constants.REST_URL}/actors/id/${producerId}`).then(
      response => {
        const producer = response.data;
        this.setState({
          producerName: producer.name,
          producerAddress: producer.address
        });
        axios
          .get(`${constants.REST_URL}/actors/${producer.address}/farms`)
          .then(
            response => {
              this.setState({ farms: response.data, status: "complete" });
            },
            error => {
              this.setState({ status: "complete" });
            }
          );
      },
      error => {
        //TODO: do something with the error
        this.setState({ status: "complete" });
      }
    );
  }

  onChangeSelectedFarm(event) {
    this.setState({ selectedFarm: event.target.value });
  }
  onChangeAltitude(event) {
    this.setState({ altitude: event.target.value });
  }
  onChangeVariety(event) {
    this.setState({ variety: event.target.value });
  }
  onChangeProcess(event) {
    this.setState({ process: event.target.value });
  }
  onChangeBatchSize(event) {
    this.setState({ batchSize: event.target.value });
  }

  newFarmClick() {
    this.props.setActiveItem(1);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const batchSize = this.state.batchSize * 100;
    if (this.props.isUpdate != null) {
      const stackId = this.contracts.CoffeeBatchFactory.methods.cooperativeUpdateCoffeeBatch.cacheSend(
        this.props.match.params.id,
        this.state.selectedFarm,
        this.state.altitude,
        this.web3.utils.utf8ToHex(this.state.variety),
        this.web3.utils.utf8ToHex(this.state.process),
        batchSize,
        this.state.producerAddress,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    } else {
      const stackId = this.contracts.CoffeeBatchFactory.methods.cooperativeAddCoffeeBatch.cacheSend(
        this.state.selectedFarm,
        this.state.altitude,
        this.web3.utils.utf8ToHex(this.state.variety),
        this.web3.utils.utf8ToHex(this.state.process),
        batchSize,
        this.state.producerAddress,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    }
  }

  render() {
    if (this.state.farms == null || this.state.farms.length == 0) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.coffeeBatch}
              url="farms"
              buttonText={contentStrings.createFarm}
              message={contentStrings.emptyFarmMessage}
              onButtonClick={this.newFarmClick}
            />
          );
          break;

        default:
          return <Loading />;
          break;
      }
    }
    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.coffeeBatches}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <Label for="">
                      {contentStrings.producer} {contentStrings.name}
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={this.state.producerName}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.farm}</Label>
                    <select
                      id="inputState"
                      className="form-control  mb-3"
                      onChange={this.onChangeSelectedFarm}
                      value={this.state.selectedFarm}
                    >
                      <option value="0">{contentStrings.selectFarm}</option>
                      {this.state.farms.map(function(farm) {
                        return (
                          <option value={farm.id} key={farm.id}>
                            {farm.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.altitude}</Label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control "
                        placeholder={contentStrings.altitudePlaceholder}
                        aria-label=""
                        aria-describedby="basic-addon2"
                        onChange={this.onChangeAltitude}
                        value={this.state.altitude}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {contentStrings.MAMSL}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.variety}</Label>
                    <input
                      className="form-control  mb-3"
                      type="text"
                      placeholder={contentStrings.varietyPlaceholder}
                      onChange={this.onChangeVariety}
                      value={this.state.variety}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.selectProcess}</Label>
                    <select
                      id="inputState"
                      className="form-control mb-3"
                      onChange={this.onChangeProcess}
                      value={this.state.process}
                    >
                      <option value="0">{contentStrings.process}</option>
                      {Object.keys(coffeeBatchProcesses).map(function(
                        processKey
                      ) {
                        return (
                          <option value={processKey}>
                            {coffeeBatchProcesses[processKey]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.batchSize}</Label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control "
                        placeholder={contentStrings.batchSizePlaceholder}
                        aria-label=""
                        aria-describedby="basic-addon2"
                        onChange={this.onChangeBatchSize}
                        value={this.state.batchSize}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {contentStrings.ql}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-seedling fa-sidebar" />
                    <span className="ml-1">{this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: activeMenuItem => dispatch(clickMenuItem(activeMenuItem))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NewCoffeeBatch)
);
