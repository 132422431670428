import React, { Component } from "react";
import _ from "lodash";
import "./TastingCard.css";
import constants from "../constants";
import contentStrings from "../constants/localization";
import Blockies from "react-blockies";

class TastingsCard extends Component {
  render() {
    var tastings = null;
    if (this.props.tastings == null || this.props.tastings.length == 0) {
      return "";
    } else {
      tastings = this.props.tastings;
    }

    return (
      <div className="card card-small user-teams mb-4">
        <div className="card-header border-bottom">
          <h6 className="m-0">{contentStrings.cupProfiles}</h6>
        </div>
        <div className="card-body p-0">
          <div className="container-fluid">
            {_.map(tastings, function(tasting, index) {
              const cuppingNote = tasting.cuppingNote / 100;
              return (
                <div className="row px-3" key={index}>
                  <div className="user-teams__image col-2 col-sm-1 col-lg-2 p-0 my-auto">
                    <a
                      href={`/actors/${tasting.taster_address}`}
                      target="_blank"
                    >
                      {tasting.taster_image_hash != "" ? (
                        <img
                          src={`${constants.IPFS_URL}/${
                            tasting.taster_image_hash
                          }`}
                          width="80"
                          className="user-avatar rounded mr-2"
                        />
                      ) : (
                        <Blockies
                          seed={tasting.taster_address}
                          size={8}
                          scale={10}
                          className="identicon user-avatar rounded mr-2"
                        />
                      )}
                    </a>
                  </div>
                  <div className="col user-teams__info pl-3">
                    <h6 className="m-0 title-link">
                      <a
                        href={`/actors/${tasting.taster_address}`}
                        target="_blank"
                      >
                        {tasting.taster_name}
                      </a>
                    </h6>
                    <span className="text-light">
                      {tasting.aroma}, {tasting.sweetness}, {tasting.flavor},{" "}
                      {tasting.acidity}, {tasting.body}, {tasting.aftertaste}{" "}
                      {tasting.image_hash != "" && (
                        <div>
                          <a
                            target="_blank"
                            href={`${constants.IPFS_URL}/${tasting.image_hash}`}
                          >
                            {contentStrings.cupProfilePreview}
                          </a>
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="col user-teams__info pl-3">
                    <h6 className="ml-auto "> {cuppingNote * 100}/100</h6>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TastingsCard;
