export default class User {
  constructor(userRaw, drizzle) {
    const utils = drizzle.web3.utils;
    this.name = utils.hexToUtf8(userRaw[0]);
    this.typeOfActor = utils.hexToUtf8(userRaw[1]);
    this.country = utils.hexToUtf8(userRaw[2]);
    this.region = utils.hexToUtf8(userRaw[3]);
    this.email = utils.hexToUtf8(userRaw[4]);
    this.imageHash = userRaw[5];
    this.bio = userRaw[6];
  }
}
