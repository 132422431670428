import contentStrings from "../constants/localization";

let coffeeBatchProcesses = {
    washed: contentStrings.washed,
    natural: contentStrings.natural,
    honey: contentStrings.Honey,
    semiHoney: contentStrings.semiHoney,
}


export default coffeeBatchProcesses;