import React, { Component } from "react";
import DataTable from "./DataTable";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import QRCode from "qrcode.react";
import Modal from "react-awesome-modal";
import copy from "copy-to-clipboard";
import "./CoffeeBatches.css";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { connect } from "react-redux";
import CheckAccountPermissions from "./CheckAccountPermissions";
import coffeeBatchProcesses from "../constants/choices";

//TODO: Reload datatable on blockchain confirm
//TODO: show tostify
//TODO: change links of qr to their own component
class CoffeeBatches extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      coffeeBatches: null,
      isLightboxOpen: false,
      lightboxQR: null,
      selectedCoffeeBatch: 0,
      status: "initialized",
      accountType: user.typeOfActor
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.downloadQR = this.downloadQR.bind(this);
    this.copyText = this.copyText.bind(this);
  }

  openModal(coffeeBatch) {
    this.setState({
      isLightboxOpen: true,
      selectedCoffeeBatch: coffeeBatch
    });
  }

  closeModal() {
    this.setState({
      isLightboxOpen: false
    });
  }

  //TODO: change download name
  downloadQR() {
    var canvas = document.getElementById("downloadCanvas");
    var img = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    window.location.href = img;
    this.closeModal();
  }

  copyText() {
    const copyUrl = `${constants.WEBSITE_URL}/coffeeBatches/${
      this.state.selectedCoffeeBatch
    }`;
    copy(copyUrl);
    //TODO: show toastify
    this.closeModal();
  }

  componentDidMount() {
    let url = `${constants.REST_URL}/actors/${
      this.props.drizzleState.accounts[0]
    }/coffeeBatches`;
    if (this.props.accountType == "cooperative") {
      url = `${constants.REST_URL}/cooperative/${
        this.props.drizzleState.accounts[0]
      }/coffeeBatches`;
    }
    axios.get(url).then(
      response => {
        console.log(response);
        this.setState({ coffeeBatches: response.data, status: "complete" });
      },
      error => {
        //TODO: do something with the error
        this.setState({ status: "complete" });
      }
    );
  }

  //TODO: row props not changing on update
  render() {
    const openModal = this.openModal;
    let accountType = this.state.accountType;

    if (
      this.state.coffeeBatches == null ||
      this.state.coffeeBatches.length == 0
    ) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.coffeeBatches}
              titleSingular={contentStrings.coffeeBatch}
              url="coffeeBatches"
              buttonIcon="fas fa-seedling"
            />
          );
          break;

        default:
          return <Loading />;
          break;
      }
    }

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    const columns = [
      contentStrings.qrCode,
      contentStrings.farm,
      contentStrings.variety,
      contentStrings.altitude,
      contentStrings.process,
      contentStrings.batchSize,
      contentStrings.actionsColumn
    ];
    //TODO: Capitalize Process
    //TODO: Get farm Name
    const rows = [];
    _.forEach(this.state.coffeeBatches, function(coffeeBatch) {
      var currentCoffeeBatch = [];
      var linkUrl = `/dashboard/coffeeBatches/edit/${coffeeBatch.id}`;

      if (accountType == "cooperative") {
        linkUrl = `/dashboard/producers/coffeeBatches/edit/${coffeeBatch.id}`;
      }

      currentCoffeeBatch.push(
        <div className="mt-2">
          <a
            href="javascript:void(0)"
            value="1"
            onClick={() => {
              openModal(coffeeBatch.id);
            }}
          >
            <QRCode
              size={80}
              value={`${constants.WEBSITE_URL}/coffeeBatches/${
                currentCoffeeBatch.id
              }`}
            />
          </a>
        </div>
      );
      currentCoffeeBatch.push(coffeeBatch.farm_name);
      currentCoffeeBatch.push(coffeeBatch.variety);
      currentCoffeeBatch.push(coffeeBatch.altitude);
      currentCoffeeBatch.push(coffeeBatchProcesses[coffeeBatch.process]);
      currentCoffeeBatch.push(coffeeBatch.size / 100);
      currentCoffeeBatch.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link to={linkUrl} className="">
            <button type="button" className="btn btn-accent form-control  ">
              <i className="fas fa-edit" />
            </button>
          </Link>
        </div>
      );
      rows.push(currentCoffeeBatch);
    });

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.coffeeBatchTitle}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              <Link to="/dashboard/coffeeBatches/new" className="">
                <button type="button" className="btn btn-accent  form-control">
                  <i className="fas fa-apple-alt mr-2" />
                  {contentStrings.newCoffeeBatch}
                </button>
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeCoffeeBatches}</h6>
              </div>
              <DataTable id="currentBatches" columns={columns} rows={rows} />
            </div>
          </div>
          <Modal
            visible={this.state.isLightboxOpen}
            width="400"
            height="400"
            effect="fadeInUp"
            onClickAway={this.closeModal}
          >
            <div className="modalDiv">
              <QRCode
                id="downloadCanvas"
                size={400}
                value={`${constants.WEBSITE_URL}/coffeeBatches/${
                  this.state.selectedCoffeeBatch
                }`}
              />
              <a
                href="javascript:void(0);"
                className="modalLink"
                onClick={this.copyText}
              >
                <i className="fas fa-copy mr-2" />
                {contentStrings.copy}
              </a>
              <a
                href="javascript:void(0);"
                className="modalLink ml-3"
                onClick={this.downloadQR}
              >
                <i className="fas fa-download mr-2" />
                {contentStrings.download}
              </a>
              <a
                href="javascript:void(0);"
                className="modalLink ml-3"
                onClick={this.closeModal}
              >
                <i className="fas fa-window-close mr-2" />
                {contentStrings.close}
              </a>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { store: state };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(CoffeeBatches)
);
