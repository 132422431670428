import React, { Component } from "react";
import coffee from "../assets/coffee.jpg";
import QRCode from "qrcode.react";
import Moment from "react-moment";
import "./CoffeeCard.css";
import contentStrings from "../constants/localization";
import coffeeBatchProcesses from "../constants/choices";
import Blockies from "react-blockies";
import constants from "../constants";
import { InputGroupAddon, Input, InputGroup, Button } from "reactstrap";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import { css, target } from "glamor";

class CoffeeCard extends Component {
  constructor(props) {
    super(props);
    this.copyText = this.copyText.bind(this);
  }

  copyText() {
    copy(this.props.farmer.address);
    toast(`📋 ${contentStrings.copyMessage}`, {
      progressClassName: css({
        background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
      })
    });
  }

  render() {
    var tastings = null;
    if (this.props.tastings != null) {
      tastings = this.props.tastings[0];
    }

    return (
      <div className="card card-small user-details mb-4">
        <div className="card-header p-0">
          <div className="user-details__bg">
            <img src={coffee} alt="Coffee Details Background Image" />
          </div>
        </div>
        <div className="card-body p-0 pt-3">
          <div className="user-details__avatar mx-auto">
            {this.props.farmer.image_hash != "" ? (
              <img
                src={`${constants.IPFS_URL}/${this.props.farmer.image_hash}`}
                width="80"
                className="user-avatar rounded-circle mr-2"
              />
            ) : (
              <Blockies
                seed={this.props.farmer.address}
                size={8}
                scale={10}
                className="identicon user-avatar rounded-circle mr-2"
              />
            )}
          </div>
          <div className="user-details__user-data p-4 text-center">
            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.farmer}</span>
                <span>{this.props.farmer.name} </span>
              </div>
            </div>
            {this.props.farmer.bio != null && (
              <div className="row mb-3">
                <div className="col">
                  <span>{contentStrings.shortBio}</span>
                  <span>{this.props.farmer.bio}</span>
                </div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col">
                <span>{contentStrings.regionColumn}</span>
                <span>
                  {this.props.farmer.region}, {this.props.farmer.country}
                </span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span>{contentStrings.contactInformation}</span>
                <span>{this.props.farmer.email}</span>
              </div>
            </div>
            {this.props.coffeeBatch.id == 21 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSez8QnL00t6Jx83jay4DIst2Yv4HtCt0tNSXb72xgdaHtcs9g/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 22 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdDpDBeP5pTA6yxgI7iGE9V4szrfrbkhZwiWexxv_-zelNFbw/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 23 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdR4vOzcpSwK7NVEuRhUKByzXXZPekhP3YvAgW8WkOQhgEfEg/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 24 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfu8VCVbSNUxkSN_hytiZPsn6-UNUk3yQ-0fN-hVWbD3Z-qUw/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 25 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfYML39613bPppvMZgcoSx2D4UKge1O_lWex2nxotMApmmH_Q/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 26 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeWgvkib_Ly67c97o-FaSH132OboI4Mz8TZP_1wxHEIBF7KlQ/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 27 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe3xW0AEeGo14KZKCkhPWU_WiYMV5ygiWLKT0XHq9bcPFWKog/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 28 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdlhq-RpfNbCHIhm2HnqfPI46_CnJklxuFAMzDCXpn0QBd8Tg/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 29 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSd4KjrUrmVNdjWC0_UlPqNZ1-NJiBWaQMmlACF3Mj9p75RFkw/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 32 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSewunP09JFsS3xYPOWc6qqbC6GC4PVFQXXa80PPNRVMu7eYBQ/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 31 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfIcrkx5pd28zwiFh32h5vA9TURxagaJJmbsHBrLcPoz8MOHA/viewform?usp=sf_link"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 33 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSewunP09JFsS3xYPOWc6qqbC6GC4PVFQXXa80PPNRVMu7eYBQ/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : this.props.coffeeBatch.id == 34 ? (
              <div className="user-details__tags  text-center m-2 mt-2">
                <a
                  className="btn burner-button btn-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSez8QnL00t6Jx83jay4DIst2Yv4HtCt0tNSXb72xgdaHtcs9g/viewform"
                  target="blank"
                >
                  <i className={`mr-2 fas fa-star ${this.props.iconClass}`} />
                  {contentStrings.rateCoffee}
                </a>
              </div>
            ) : (
              <>
                <div className="row mb-3">
                  <div className="col">
                    <span>{contentStrings.accountAddress}</span>
                    <span>
                      <InputGroup>
                        <InputGroupAddon addonType="append" className="mx-auto">
                          <Input readOnly value={this.props.farmer.address} />
                          <Button
                            className="btn btn-accent"
                            onClick={this.copyText}
                          >
                            {contentStrings.copy}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </span>
                  </div>
                </div>
                <div className="user-details__tags  text-center m-2 mt-2">
                  <QRCode
                    id="downloadCanvas"
                    size={230}
                    value={this.props.farmer.address}
                    className="m-3"
                  />
                </div>
                <div className="user-details__tags  text-center m-2 mt-2">
                  <a
                    className="btn burner-button "
                    href={`https://xdai.io/${
                      this.props.farmer.address
                    };1;tip%20the%20farmer`}
                    target="blank"
                  >
                    <i className={`fas fa-fire ${this.props.iconClass}`} />{" "}
                    {contentStrings.tipBurner}
                  </a>
                </div>
              </>
            )}
          </div>

          <div className="user-details__user-data border-top border-bottom p-4 text-center">
            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.farmName}</span>
                <span>{this.props.farm.name}</span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.regionColumn}</span>
                <span>
                  {this.props.farm.village}, {this.props.farm.region}
                </span>
              </div>
              <iframe
                className="mt-2 mx-auto px-4 embed-responsive"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAkqEYG5LG4ZgOzTK6O7yY-K9manVHQrfk
&q=${this.props.farm.village}+${this.props.farm.region}&zoom=6`}
                allowFullScreen
              />
            </div>

            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.variety}</span>
                <span>{this.props.coffeeBatch.variety} </span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.process}</span>
                <span>
                  {coffeeBatchProcesses[this.props.coffeeBatch.process]}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col w-50">
                <span>{contentStrings.batchSize}</span>
                <span>
                  {this.props.coffeeBatch.size} {contentStrings.ql}
                </span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.altitude}</span>
                <span>
                  {this.props.coffeeBatch.altitude} {contentStrings.MAMSL}
                </span>
              </div>
              <div className="row ">
                <div className="col mx-4">
                  <span>{contentStrings.farmStory}</span>
                  <span>{this.props.farm.story}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </div>
    );
  }
}

export default CoffeeCard;
