import React, { Component } from "react";
import DataTable from "./DataTable";
import DataTableButton from "./DataTableButton";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";

//TODO: Reload datatable on blockchain confirm
//TODO: show tostify

class Farms extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      farms: null,
      status: "initialized",
      accountType: user.typeOfActor
    };
  }

  componentDidMount() {
    let url = `${constants.REST_URL}/actors/${
      this.props.drizzleState.accounts[0]
    }/farms`;
    if (this.props.accountType == "cooperative") {
      url = `${constants.REST_URL}/cooperative/${
        this.props.drizzleState.accounts[0]
      }/farms`;
    }
    axios.get(url).then(
      response => {
        this.setState({ farms: response.data, status: "complete" });
      },
      error => {
        //TODO: do something with the error
        this.setState({ status: "complete" });
      }
    );
  }

  render() {
    let accountType = this.state.accountType;

    if (this.state.farms == null || this.state.farms.length == 0) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.farms}
              titleSingular={contentStrings.farm}
              gender="f"
              url="farms"
            />
          );
          break;

        default:
          return <Loading />;
          break;
      }
    }

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    const columns = [
      contentStrings.nameColumn,
      contentStrings.countryColumn,
      contentStrings.department,
      contentStrings.village,
      contentStrings.story,
      contentStrings.actionsColumn
    ];
    const rows = [];
    _.forEach(this.state.farms, function(farm) {
      var currentFarm = [];
      var linkUrl = `/dashboard/farms/edit/${farm.id}`;

      if (accountType == "cooperative") {
        linkUrl = `/dashboard/producers/farms/edit/${farm.id}`;
      }

      currentFarm.push(farm.name);
      currentFarm.push(farm.country);
      currentFarm.push(farm.region);
      currentFarm.push(farm.village);
      currentFarm.push(farm.story);
      currentFarm.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <Link to={linkUrl} className="">
            <button type="button" className="btn btn-accent form-control  ">
              <i className="fas fa-edit " />
            </button>
          </Link>
        </div>
      );
      rows.push(currentFarm);
    });

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.farms}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              <Link to="/dashboard/farms/new" className="">
                <button type="button" className="btn btn-accent  form-control">
                  <i className="fas fa-apple-alt mr-2 " />
                  {contentStrings.newFarm}
                </button>
              </Link>
            </div>
          </div>
          <div className="row ">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeFarm}</h6>
              </div>
              <DataTable id="currentBatches" columns={columns} rows={rows} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Farms);
