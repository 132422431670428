import React, { Component } from "react";
import DataTable from "./DataTable";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import coffeeBatchProcesses from "../constants/choices";

//TODO: Reload datatable on blockchain confirm
//TODO: show tostify

class Certificates extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.state = {
      certificates: null,
      coffeeBatches: null,
      status: "initialized"
    };
  }

  componentDidMount() {
    axios
      .get(
        `${constants.REST_URL}/actors/${
          this.props.drizzleState.accounts[0]
        }/certificates`
      )
      .then(
        response => {
          this.setState({ certificates: response.data, status: "complete" });
        },
        error => {
          //TODO: do something with the error
          this.setState({ status: "complete" });
        }
      );

    axios
      .get(
        `${constants.REST_URL}/actors/${
          this.props.drizzleState.accounts[0]
        }/allowers/coffeeBatches`
      )
      .then(
        response => {
          this.setState({ coffeeBatches: response.data, status: "complete" });
        },
        error => {
          //TODO: do something with the error
          this.setState({ status: "complete" });
        }
      );
  }

  render() {
    if (
      this.state.certificates == null ||
      this.state.certificates.length == 0
    ) {
      switch (this.state.status) {
        case "complete":
          return (
            <EmptyDashboardTable
              title={contentStrings.certificates}
              url="certificates"
              titleSingular={contentStrings.certificate}
              gender="m"
              buttonIcon="fas fa-award"
            />
          );
          break;

        default:
          return <Loading />;
          break;
      }
    }

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    const columns = [
      contentStrings.imageColumn,
      contentStrings.nameColumn,
      contentStrings.descriptionColumn
    ];
    const rows = [];
    _.forEach(this.state.certificates, function(certificate) {
      var currentCertificate = [];
      currentCertificate.push(
        <img
          src={`${constants.IPFS_URL}/${certificate.image_hash}`}
          width="80"
          className=" rounded-circle"
        />
      );
      currentCertificate.push(certificate.name);
      currentCertificate.push(certificate.description);
      rows.push(currentCertificate);
    });
    let columnsCoffeeBatch = [];
    let rowsCoffeeBatches = [];

    if (
      this.state.coffeeBatches != null &&
      this.state.coffeeBatches.length != 0
    ) {
      columnsCoffeeBatch = [
        contentStrings.farm,
        contentStrings.variety,
        contentStrings.altitude,
        contentStrings.process,
        contentStrings.batchSize,
        contentStrings.actionsColumn
      ];
      rowsCoffeeBatches = [];
      _.forEach(this.state.coffeeBatches, function(coffeeBatch) {
        var url = `/dashboard/coffeeBatches/${
          coffeeBatch.id
        }/certificates/assign`;
        var btnIcon = "fas fa-award mr-2";
        var currentFarm = [];

        currentFarm.push(coffeeBatch.farm_name);
        currentFarm.push(coffeeBatch.variety);
        currentFarm.push(coffeeBatch.altitude);
        currentFarm.push(coffeeBatchProcesses[coffeeBatch.process]);
        currentFarm.push(coffeeBatch.size / 100);

        currentFarm.push(
          <div
            className="btn-group btn-group-sm"
            role="group"
            aria-label="Table row actions"
          >
            <Link to={url} className="">
              <button type="button" className="btn btn-accent form-control ">
                <i className={btnIcon} /> {contentStrings.assignCertificate}
              </button>
            </Link>
          </div>
        );
        rowsCoffeeBatches.push(currentFarm);
      });
    }

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.certificates}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              <Link to="/dashboard/certificates/new" className="">
                <button type="button" className="btn btn-accent form-control">
                  <i className="fas fa-award mr-2" />
                  {contentStrings.newCertificate}
                </button>
              </Link>
            </div>
          </div>
          <div className="row col-lg-12 col-md-12">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.certificatesResume}</h6>
              </div>
              <DataTable
                id="currentCertificates"
                columns={columns}
                rows={rows}
              />
            </div>
          </div>
          {rowsCoffeeBatches.length != 0 && (
            <div className="row col-lg-12 col-md-12 ">
              <div className="col mb-4">
                <div className="mb-3">
                  <h6 className="mt-4">{contentStrings.coffeeBatches}</h6>
                </div>
                <DataTable
                  id="currentBatches"
                  columns={columnsCoffeeBatch}
                  rows={rowsCoffeeBatches}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Certificates);
